@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap');
:root {
    --main-bg: #1F2734;
    --text-color: #fff;
    --theme-color: #1675FF;
    --theme-secondary-color: rgba(22, 117, 255, 0.25);
    --theme-bg-color: rgba(52, 120, 253, 0.15);
    --dark-bg: rgba(22, 117, 255, .1);
}

html, body, * {
    font-family: 'Lexend', sans-serif;
    scroll-behavior: smooth;
}

body {
    background: var(--main-bg);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}

* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
}

* ::-webkit-scrollbar-thumb {
    background: var(--theme-color);
    border-radius: 8px;
}

* ::-webkit-scrollbar-track {
    background: var(--dark-bg);
}

*, *::before, *::after {
    box-sizing: border-box;
}

.btn {
    border: none;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 600;
    padding: 6px 8.5px;
    border-radius: 8px;
    border: 1.5px solid transparent;
}

button * {
    text-decoration: none;
}

.btn-snb {
    padding: 10px 15px;
    font-size: 16px;
    background: var(--theme-color);
    margin: 0 8px 0 8px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.btn-snb > svg {
    margin-right: 8px;
}

.btn-snb-outline {
    background: transparent;
    border: 1.5px solid var(--theme-color);
    color: var(--theme-color);
}

.btn-snb-outline-none {
    background: transparent;
    border: 1.5px solid transparent;
    color: var(--theme-color);
}

.btn:hover:not(:disabled) {
    cursor: pointer;
}

.btn-nrm {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: fit-content;
    padding: 8px 12px;
    font-size: 16px;
    transition: .25s;
    text-transform: none;
}

.dflx {
    display: flex;
    align-items: center;
}

.btn-nrm-sm {
    font-size: 14px;
}

.btn-nrm-xsm {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.btn-nrm svg {
    margin-right: 8px;
}

.btn-nrm:hover:not(:disabled) {
    background: rgba(0, 85, 255, .75);
    transition: .25s;
}

.btn-nrm:disabled {
    background: rgba(0, 85, 255, .75);
    color: rgba(255, 255, 255, .75);
}

.mr-2 {
    margin-right: 8px;
}

.btn-dng {
    padding: 8px 12px;
    font-size: 15px;
    background: red;
    margin: 0 8px 0 8px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}

.auth-form-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 75px);
}

.auth-form {
    background: var(--dark-bg);
    width: 504px;
    padding: 24px;
    border-radius: 15px;
}

.auth-form > * {
    display: block;
}

.auth-form-title {
    font-weight: 700;
    font-size: 28px;
}

.auth-form-inputs {
    margin-top: 18px;
}

.auth-form-input {
    width: 100%;
    background: var(--main-bg);
    border: none;
    color: #fff;
    padding: 12px 16px;
    font-size: 18px;
    margin-bottom: 12px;
    border-radius: 6px;
}

.auth-form-btn {
    background: var(--theme-color);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    transition: .25s;
}

.auth-form-btn:hover:not(:disabled) {
    background: rgba(52, 120, 253, .5);
    transition: .25s;
}

.auth-form-sub {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.auth-form-sub a {
    color: var(--theme-color);
    text-decoration: none;
}

.auth-form-captcha {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}

.auth-form-error {
    background: rgba(255, 0, 0, .75);
    color: #fff;
    padding: 12px;
    margin-top: 8px;
    border-radius: 6px;
}

.dplfx {
    display: flex;
    align-items: center;
}

.dplfx > .userorders-table-status {
    margin-left: 8px;
}

.alert {
    background: var(--dark-bg);
    font-weight: 600;
    padding: 18px;
    border-radius: 12px;
    font-size: 20px;
    padding-bottom: 9px;
}

.item-box-lg-bottom1 {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.item-box-lg-bottom1 .btn {
    margin-left: 0;
}

.admin-dashboard-layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.admin-dashboard-sidebar {
    width: 12.5%;
    background: var(--dark-bg);
    height: 100%;
}

.admin-dashboard-content {
    width: 87.5%;
}

.admin-dashboard-sidebar-branding {
    height: 125px;
    padding: 38px;
}

.admin-dashboard-sidebar-branding-img {
    width: 100%;
}

.admin-sidebar-option {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 14px;
    color: rgba(255, 255, 255, .5);
    border-left: 4px solid transparent;
    transition: .25s;
    text-decoration: none;
    font-weight: 500;
}

.admin-sidebar-option:hover, .admin-sidebar-option-active {
    border-left: 4px solid var(--theme-color);
    color: var(--theme-color);
    transition: .25s;
}

.admin-sidebar-option:hover {
    cursor: pointer;
}

.admin-sidebar-option svg {
    margin-left: 6px;
}

.admin-sidebar-option-text {
    margin-left: 14px;
    text-decoration: none;
}

.admin-dashboard-select-view {
    margin-top: 32px;
}

.admin-dashboard-select-view > .btn:first-of-type {
    margin-left: 0;
}

.admin-dashboard-index {
    width: 95%;
    margin-left: 2.5%;
}

.admin-dashboard-index-stats {
    display: flex;
    justify-content: space-evenly;
    margin-top: 32px;
}

.admin-dashboard-index-stats > .admin-dashboard-stats-box:first-of-type {
    margin-left: 0;
}

.admin-dashboard-stats-box {
    width: 30%;
    background: var(--dark-bg);
    border-radius: 15px;
    margin-left: 5%;
}

.admin-dashboard-stats-value-content {
    padding: 14px 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.admin-dashboard-stats-value-icon {
    font-size: 28px;
    padding: 0 8px;
}

.admin-dashboard-stats-title {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.admin-dashboard-stats-title-text {
    color: rgba(255, 255, 255, .8);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.admin-dashboard-stats-value-text {
    font-size: 24px;
}

.admin-dashboard-stats-title svg {
    margin-right: 4px;
}

.admin-dashboard-stats-graph-container {
    min-height: 48px;
    padding: 24px;
}

.btn-select-view {
    background: var(--dark-bg);
    border: 1px solid transparent;
    padding: 8px 12px;
    font-size: 15px;
    margin: 0 12px 0 0;
}

.btn-select-view-active {
    border: 1px solid var(--theme-color);
}

.item-title-margin {
    margin: 32px 0;
}

.user-home-welcome-title {
    font-size: 32px;
    font-weight: 700;
    padding: 0;
}

.user-home-welcome-title svg {
    color: var(--theme-color);
}

.text-underline {
    text-decoration: underline;
    text-decoration-color: var(--theme-color);
}

.user-home-welcome-text {
    color: #999;
    font-weight: 600;
    font-size: 14px;
    margin-top: -4px;
}

.user-home-hero > .userhome-hero-item:first-of-type {
    margin-left: 0;
}

.userorders-table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 .5em;
}

.userorders-table th {
    text-align: left;
    padding: 12px 18px;
}

.userorders-table td {
    background: var(--dark-bg);
    margin: 0;
    padding: 12px 18px;
    margin-top: 1em;
    text-align: left;
    font-size: 15.5px;
}

.userorders-table > tbody > tr > td:first-of-type {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.userorders-table tbody > tr button {
    width: 100%;
}

.userorders-table > tbody > tr > td:last-of-type {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.userorders-table-status {
    text-transform: uppercase;
    font-size: 13.5px;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 6px;
    width: fit-content;
    padding: 6px 7px;
}

.form-info {
    background: rgba(31, 122, 239, 0.3);
    color: rgba(31, 122, 239);
    border: 1px solid rgba(31, 122, 239, 0.8);
    padding: 12px;
    margin-top: 8px;
    border-radius: 6px;
}

.userorders-table-status-processing {
    background: rgba(31, 122, 239, 0.3);
    border: 1px solid rgba(31, 122, 239, 0.8);
    color: rgba(31, 122, 239);
}

.userorders-table-status-awaiting_payment {
    background: rgba(255, 140, 0, 0.3);
    border: 1px solid rgba(255, 140, 0, 0.8);
    color: rgba(255, 140, 0);
}

.userorders-table-status-cancelled {
    background: rgba(255, 0, 0, 0.3);
    border: 1px solid rgba(255, 0, 0, 0.8);
    color: rgba(255, 0, 0);
}

.userorders-table-status-completed {
    background: rgba(0, 200, 0, 0.3);
    border: 1px solid rgba(0, 200, 0, 0.8);
    color: rgba(0, 215, 0);
}

.admin-dashboard-index-content {
    display: flex;
}

.admin-dashboard-index-orders {
    width: 75%;
}

.admin-dashboard-stats-right {
    width: 24%;
    margin-left: 1%;
    padding-top: 32px;
}

.admin-dashboard-small-stats-box {
    background: var(--dark-bg);
    border-radius: 8px;
    width: 100%;
    padding: 14px 18px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.dashboard-page-content {
    width: 95%;
    margin-left: 2.5%;
}

.capitalize {
    text-transform: capitalize;
}

.dashboard-page-split-content {
    display: flex;
    width: 100%;
}

.dashboard-page-split-left {
    width: 75%;
}

.dashboard-page-split-right {
    width: 23%;
    margin-left: 2%;
}

.item-create-box {
    background: var(--dark-bg);
    border-radius: 12px;
    padding: 14px 16px;
}

.item-title-margin-small {
    margin: 0 2px;
}

.item-title-margin-small > .user-home-welcome-title {
    font-size: 28px;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.item-create-box .btn {
    width: 100%;
    margin-left: 0;
    margin-top: 12px;
}

.input {
    width: 100%;
    background: var(--main-bg);
    border: none;
    color: #fff;
    padding: 12px 16px;
    font-size: .9375rem;
    margin-bottom: 12px;
    border-radius: .375rem;
}

.dashboard-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-loading-full-page {
    height: calc(100vh - 328px);
}

.mt-4 {
    margin-top: 32px;
}

.input-group-checkbox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.dashboard-page-view-select {
    display: flex;
    width: 100%;
}

.dashboard-page-view-select > .btn-view-sel:first-of-type {
    margin-left: 0;
}

.btn-view-sel {
    width: 48.5%;
    margin-left: 3%;
    background: var(--dark-bg);
    border: 1px solid transparent;
}

.btn-view-sel-active {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);  
}

.mt-2 {
    margin-top: 16px;
}

.mt-1 {
    margin-top: 8px;
}

input[type="checkbox"] {
    appearance: none;
    background-color: var(--main-bg);
    margin: 0;
    font: inherit;
    color: var(--main-bg);
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid var(--main-bg);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
  
input[type="checkbox"]::before {
    content: "";
    width: 0.95em;
    height: 0.95em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--theme-color);
}
  
input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"]:hover {
    cursor: pointer;
}

.dashboard-page {
    height: 100vh;
    overflow-y: scroll;
}

.table-pagnation {
    display: flex;
    margin: 18px 0;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.table-pagnation-text {
    padding: 12px 18px;
}

.btn-pag {
    background: var(--dark-bg);
    border: 1px solid transparent;
    margin-left: 8px;
}

.btn-pag-active {
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
}

.userticket-message-content {
    max-width: 48%;
    width: fit-content;
    flex-direction: column;
    display: flex;
}

.userticket-message { 
    width: fit-content;
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 17.5px;
    display: flex;
    flex-direction: column;
}

.userticket-message-container {
    width: 100%;
    display: flex;
    margin-top: 42px;
}

.userticket-message-container-user {
    justify-content: left;
}

.userticket-message-container-user > .userticket-message-content {
    text-align: left;
    align-items: flex-start;
}

.userticket-message-container-staff {
    justify-content: right;
}

.userticket-message-container-staff > .userticket-message-content {
    text-align: right;
    align-items: flex-end;
}

.userticket-message-staff {
    background: var(--theme-color);
}

.userticket-message-info-text {
    color: rgba(255, 255, 255, .45);
    font-size: 16px;
    margin-top: 2px;
}

.userticket-message-user {
    background: var(--dark-bg);
}

.userticket-messages {
    min-height: 350px;
}

.ticket-closed-alert {
    background: var(--dark-bg);
    padding: 14px 18px;
    font-size: 19.5px;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.ticket-closed-alert svg {
    margin-right: 8px;
}

.ticket-closed-alert a {
    color: var(--theme-color);
}

.userticket-send-message {
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 36px;
}

.userticket-send-message textarea {
    background: var(--main-bg);
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 12px 14px;
    color: #fff;
    margin-top: 12px;
}

.userticket-send-message-options {
    width: 100%;
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.userticket-send-message-options .btn {
    padding: 6px 8.5px;
    font-size: 14px;
}

.userticket-send-message-options .btn > svg {
    margin-right: 4px;
}

.userticket-send-message-options button {
    margin-right: 0;
}

.ml-0 {
    margin-left: 0;
}

.userticket-message-title {
    font-size: 22px;
    font-weight: 700;
}

.userticket-message-text {
    font-size: 18px;
    color: rgba(255, 255, 255, .75);
}

.userorders-table-status-awaiting_staff_reply {
    background: rgba(255, 0, 0, 0.3);
    border: 1px solid rgba(255, 0, 0, 0.8);
    color: rgba(255, 0, 0);
}

.userticket-message > * {
    display: block;
}

.userticket-message > .text-line:first-of-type {
    margin-top: 0;
}

.item-values-container {
    display: flex;
    flex-direction: column;
}

.item-values-container > .item-value-container:first-of-type {
    margin-top: 0;
}

.item-value-container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

.item-content-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.item-content-container {
    width: 100%;
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 12px;
}

.item-content-container > .item-content-row:first-of-type {
    margin-top: 0;
}

.item-value-title {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, .75);
}

.userorder-content {
    background: var(--main-bg);
    padding: 18px;
    width: 100%;
    border-radius: 12px;
    height: fit-content;
    margin-top: 12px;
    overflow-y: scroll;
    white-space: "pre-wrap";
}

.userticket-order-action-comps {
    margin-left: 2%;
}

.userticket-order-actions {
    display: flex;
}

.userticket-order-action-comps {
    display: flex;
    width: 69%;
}

.userticket-order-action-comp {
    width: 32%;
    background: var(--dark-bg);
    padding: 16px;
    border-radius: 6px;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
}

.userticket-order-action-comp button {
    margin-left: 0;
    width: 100%;
    margin-top: 12px;
}

.userorder-payment-status-update-title {
    font-size: 28px;
    font-weight: 700;
}

.input-custom {
    padding: 0;
}

.user-info-bottom {
    display: flex;
    width: 100%;
    margin-top: 12px;
    justify-content: right;
}

.txt-main-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 8px;
}

.txt-main-title svg {
    margin-right: 8px;
    color: var(--theme-color);
}

.txt-title {
    display: flex;
    justify-content: space-between;
}

.userticket-order-data {
    width: 32%;
    background: var(--dark-bg);
    padding: 16px;
    border-radius: 6px;
}

.userticket-order-data button {
    margin-left: 0;
    width: 100%;
    margin-top: 12px;
}

.txt-title-left {
    font-weight: 600;
}

.txt-title-right {
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
}

.dashboard-payment-methods-content {
    display: flex;
}

.dashboard-payment-methods-content > .payment-method:first-of-type {
    margin-left: 0;
}

.input-group-split {
    display: flex;
    width: 100%;
}

.input-group-split > .input-group:first-of-type {
    width: 30%;
    margin-left: 0;
}

.input-group-split > .input-group {
    width: 68%;
    margin-left: 2%;
}

.payment-method {
    width: 49%;
    background: var(--dark-bg);
    border: none;
    color: #fff;
    padding: 12px 16px;
    font-size: 18px;
    margin-bottom: 12px;
    border-radius: 15px;
    margin-left: 2%;
}

.payment-method-title {
    font-size: 24px;
    font-weight: 600;
}

.payment-method .btn {
    margin-left: 0;
    width: 100%;
    margin-top: 12px;
}

.admin-dashboard-error {
    width: 100%;
}

.storefront-error {
    width: 100%;
    text-align: center;
}

.storefront-error-icon {
    font-size: 38px;
    color: rgba(255, 0, 0, .95);
}

.storefront-error-title {
    font-size: 25px;
    font-weight: 700;
}

.storefront-error-text {
    font-size: 19px;
}

.adminorder-data {
    display: flex;
    margin-top: 32px;
}

.storefront-cart-item-options {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.storefront-cart > .storefront-cart-item:first-of-type {
    margin-top: 0;
}

.adminorder-data-title {
    padding: 18px;
    font-size: 22px;
    font-weight: 700;
}

.adminorder-data-products {
    width: 50%;
}

.adminorder-data-products-list {
    display: flex;
    flex-wrap: wrap;
}

.adminorder-data-products-list > .storefront-cart-item:nth-child(-n+2) {
    margin-top: 0;
}

.storefront-cart-item {
    display: flex;
    flex-direction: column;
    padding: 18px;
    border-radius: 8px;
    width: 49%;
    align-items: center;
    margin-top: 18px;
    background: var(--dark-bg);
    margin-right: 1%;
}

.storefront-cart-content-title {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    width: 95%;
}

.storefront-cart-content-quantity {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    align-self: flex-end;
    margin-top: 12px;
}

.storefront-cart-item-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-item-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.storefront-cart-image {
    height: 100px;
    border-radius: 8px;
    margin-top: 4px;
    border: 1px solid rgba(255, 255, 255, .05);
}

.storefront-cart-item .storefront-quantity-select {
    margin-right: 12px;
}

.storefront-product-stock-container {
    width: 100%;
    padding-top: 18px;
    margin-top: 18px;
    border-top: 1px solid rgba(255, 255, 255, .1);
}

.storefront-cart-content-price {
    font-size: 24px;
    font-weight: 500;
    text-align: right;
    color: #fff;
    align-self: flex-end;
}

.order-timeline-event {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    background: var(--dark-bg);
    padding: 18px;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: .25s;
}

.order-timeline-event:hover {
    border: 2px solid var(--theme-color);
    background: var(--theme-bg-color);
    transition: .25s;
    cursor: pointer;
}

.order-timeline-event:hover * {
    color: var(--theme-color)!important;
}

.order-timeline-event-icon {
    color: var(--theme-color);
    font-size: 48px;
    width: 60px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.order-timeline-event-content {
    margin-left: 18px;
}

.order-timeline-event-title {
    font-size: 18px;
    font-weight: 700;
}

.order-timeline-event-text {
    font-size: 16px;
}

.order-timeline-event-timestamp {
    color: rgba(255, 255, 255, .55);
    font-size: 15px;
}

.adminorder-data-timeline {
    width: 50%;
}

@media screen and (min-width: 990px) and (max-width: 2000px) {
    .dashboard-page-content {
        width: 97.5%;
        margin-left: 1.25%;
    }

    .dashboard-page-split-content {
        display: flex;
        width: 100%;
    }
    
    .dashboard-page-split-left {
        width: 70%;
    }
    
    .dashboard-page-split-right {
        width: 28.5%;
        margin-left: 1.5%;
    }

    .admin-dashboard-sidebar {
        width: 15%;
        background: var(--dark-bg);
        height: 100%;
    }
    
    .admin-dashboard-content {
        width: 85%;
    }
}

@media screen and (min-width: 2000px) {
    .dashboard-page-content {
        width: 95%;
        margin-left: 2.5%;
    }

    .dashboard-page-split-content {
        display: flex;
        width: 100%;
    }
    
    .dashboard-page-split-left {
        width: 75%;
    }
    
    .dashboard-page-split-right {
        width: 23%;
        margin-left: 2%;
    }

    .admin-dashboard-sidebar {
        width: 12.5%;
        background: var(--dark-bg);
        height: 100%;
    }
    
    .admin-dashboard-content {
        width: 87.5%;
    }
}